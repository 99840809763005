<div class="about-dialog" fxLayout="column">
  <div class="about-header">
    <div
      class="about-logo"
      [style.background-image]="'url(../../assets/images/FT_Edge_Manager_Logo_Cyan_v1.svg)'"
    ></div>
    <div class="item-exit" fxFlex="0 0 40px">
      <ra-ui-icon-button
        (onClick)="closeButtonClicked()"
        [config]="buttonConfig"
        icon="ra-icon-ide-md-cross"
      ></ra-ui-icon-button>
    </div>
  </div>
  <div class="about-version main-font">
    <ra-ui-static-text
      [value]="'Version '+ serverVersion + UiVersion"
      [config]="textConfig"
    ></ra-ui-static-text
    >&nbsp;
  </div>
  <div class="about-content">
    <div class="main-font copy-right rights-reserved">
      <ra-ui-static-text
        [value]="'\u00A9 2022 Rockwell Automation Technologies, Inc.'"
        [config]="textConfig"
      ></ra-ui-static-text
      >&nbsp;
      <ra-ui-static-text
        [value]="'All Rights Reserved.'"
        [config]="textConfig"
      ></ra-ui-static-text>
    </div>
    <div class="main-font legal-notice">
      <ra-ui-static-text
        [value]="
          'This application is protected by copyright law and international treaties. Unauthorized reproduction or distribution of this program, or any portion of it, may result in severe civil and criminal penalties and will be prosecuted to the maximum extent possible under the law.'
        "
        [config]="textConfig"
      ></ra-ui-static-text>
    </div>
  </div>
</div>
