<div class="main-frame">
  <!-- Loader div -->
  <div
    *ngIf="isLoading"
    class="spinner-wrapper-custom"
    fxLayoutAlign="center center"
  >
    <ra-ui-loading-spinner
      id="loadingSpinnerCustomSettingsOrg"
      [size]="size"
      [animationType]="animationType"
    ></ra-ui-loading-spinner>
  </div>

  <div class="sub-container" [hidden]="isLoading">
    <div>
      <ra-ui-image
        id="placeholderImage"
        value="../../assets/images/FT-Edge.svg"
        [config]="{
          disablePadding: this.disablePadding
        }"
      >
      </ra-ui-image>
    </div>
    <div class="text-info">
      <ra-ui-static-text
        [value]="confirmState ? 'Entitlement Saved' : 'Add Enterprise Name'"
        class="form-title"
        [config]="TextConfigTitle"
      >
      </ra-ui-static-text>
    </div>
    <div class="text-info">
      <ra-ui-static-text
        class="form-subtitle"
        [value]="
          confirmState
            ? 'Returning to where you started'
            : 'Please add an Enterprise Name to associate with your entitlement.'
        "
        [config]="confirmState ? TextConfigSubtitleSuccess : TextConfigSubtitle"
      >
      </ra-ui-static-text>
    </div>
    <div class="provisioning-org-name" [hidden]="confirmState">
      <ra-ui-form [formGroup]="formGroup">
        <ra-ui-label-container
          label="Provisioning Enterprise Name"
          [config]="labelConfig"
          [isError]="isError"
        ></ra-ui-label-container>
        <ra-ui-input formControlName="name" [config]="inputConfig" (valueChange)="badWordCheck(data.value)" #data>
        </ra-ui-input>
        <div class="margin-bottom" *ngIf="!formGroup.invalid">
          <ra-ui-static-text
            class="form-subtitle margin-bottom"
            [value]="
              'Please use only Alphanumeric and _.- characters. Minimum 3, Max 256'
            "
            [config]="TextConfigSubtitle"
          >
          </ra-ui-static-text>
        </div>
        <div class="error-div" >
          <div *ngIf="formGroup.invalid || isInternalError" class="error-icon">
            <ra-ui-status-icon
              id="statusIcon"
              [icon]="activeStatusIcon"
            ></ra-ui-status-icon>
          </div>
          <div></div>
          <div class="error-text">
            <ra-ui-error-message
              [config]="errorConfig"
              [control]="nameControl"
              [errorMessages]="errorMessages"
              (errorStateChange)="setError($event)"
            ></ra-ui-error-message>
            <span *ngIf="isInternalError">
              There was an issue {{this.errorType}}. Please try {{this.errorType}} again{{this.errorType == 'Saving' ? ' or Cancel':null}}. You may also try starting the Entitlement process from where you began.
            </span>
          </div>
        </div>
      </ra-ui-form>
    </div>

    <div [ngClass]="confirmState ? 'action-buttons-success' : 'action-buttons'">
      <ra-ui-loading-spinner
      *ngIf="confirmState"
      id="loadingSpinnerCustomSettingsOrg"
      [size]="size"
      [animationType]="animationType"
    ></ra-ui-loading-spinner>
      <!-- <ra-ui-main-button
        *ngIf="confirmState"
        label="Return"
        id="return"
        [color]="themeColor.Primary"
      >
      </ra-ui-main-button> -->
      <ra-ui-main-button
        *ngIf="!confirmState"
        [disabled]="formGroup.controls.name.value == '' || formGroup.invalid"
        label="Save"
        id="Save"
        class="save-button"
        [color]="themeColor.Primary"
        (onClick)="onSubmit()"
      >
      </ra-ui-main-button>
      <div class="cancel-button" *ngIf="!confirmState">
        <ra-ui-outlined-button
          label="Cancel"
          id="cancel"
          [color]="themeColor.None"
          (onClick)="cancel()"
        >
        </ra-ui-outlined-button>
      </div>
    </div>
  </div>
</div>
