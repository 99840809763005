import { Component, OnInit } from '@angular/core';
import { onSideNavChange, animateText } from '../animation/animation';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  TreeModel,
  ITreeConfig,
  ITreeNode,
  RaUiTreeControl,
  ITreeInitializedEvent,
  ITreeNodeClickedEvent,
} from '@ra-web-tech-ui-toolkit/lists/tree';
import { SideMenuItems } from 'src/app/configurations/navigation';
import { SidenavService } from '../../utils/services/sidenav.service';
import { Nullable } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { delay, filter, map } from 'rxjs';

@Component({
  selector: 'eaas-hub-sidebar-new',
  templateUrl: './sidenav-new.component.html',
  styleUrls: ['./sidenav-new.component.scss'],
  animations: [onSideNavChange, animateText],
})
export class SidenavNewComponent implements OnInit {
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  treeControl!: RaUiTreeControl;
  treeModel: TreeModel = SideMenuItems;
  config!: ITreeConfig;
  selectedNode: Nullable<ITreeNode>;

  ngOnInit() {}

  onTreeInitialized(event: ITreeInitializedEvent) {
    this.treeControl = event.control;
  }

  onSidenavToggle(contentSlide?: boolean) {
    this.sideNavState = !this.sideNavState;
    this.linkText = this.sideNavState;
    if (!contentSlide)
      this._sidenavService.sideNavState$.next(this.sideNavState);
  }

  constructor(private _sidenavService: SidenavService, public router: Router) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.split('/')),
        map((url: Array<string>) => url[1]),
        delay(100)
      )
      .subscribe((route: any) => {
        this.urlSelection(
          this.treeModel.find((e) => {
           return e.link.includes(route);
          })
        );
      });
  }

  onClick(event: ITreeNodeClickedEvent): void {
    event.node ? this.router.navigate([event.node.link || '']) : null;
  }

  urlSelection(currentUrl: ITreeNode | undefined) {
    currentUrl ? this.treeControl.select(currentUrl) : null;
  }

}
