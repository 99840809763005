import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auto-sign-in',
  template:"<div></div>"
})
export class AutoSignInComponent implements OnInit{

  userIsLoggedIn = false;
  constructor(public auth: AuthService) {
  }
  ngOnInit(): void {
    this.login();
  }
  login() {
    const options = {
      redirect_uri: environment.appConfiguration.authConfig.redirectUri,
      audience: environment.appConfiguration.authConfig.audience,
      scope: environment.appConfiguration.authConfig.scope,
      appState: { target: '/devices' }
    };
    this.auth.loginWithRedirect(options)
  }

}
