// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.

import { CoreConfiguration } from '@rockwell-automation-inc/service';

interface privateConfiguration extends CoreConfiguration {
  apiBaseUrl : string
  appBaseUrl : string
  isFederatedLogout: boolean
  techSupportUrlPath: string
  gainsightTag: string
  factoryTalkHubBaseUrl:string
  redirectUrl:string;
}

// The list of file replacements can be found in `angular.json`.
const appConfiguration: privateConfiguration = {
  redirectUrl: "https://edgecontrol.cloud-dev.rockwellautomation.com/",
  apiBaseUrl: "https://edgemanager-api.lemans-sandbox.rockwellautomation.com",
  appBaseUrl: "https://edgemanager.lemans-sandbox.rockwellautomation.com",
  production: false,
  name: "EaaS Manager Portal",
  csApiBaseUrl: "https://common.lemans-sandbox.rockwellautomation.com",
  csPortalUrl: '',
  notificationsBaseUrl:"https://api-notifications.lemans-sandbox.rockwellautomation.com",
  isFederatedLogout: true,
  techSupportUrlPath: "/p/5794/c/5346",
  gainsightTag:" AP-AJVZXHMRUALZ-2-3",
  factoryTalkHubBaseUrl:"https://home.lemans-sandbox.rockwellautomation.com",
  authConfig: {
    domain: "factorytalkhub.us.auth0.com",
    clientId: "AMderzuDxpLgF1YJzawHtoNr1vjv0mMN",
    audience: "https://lemans.common",
    redirectUri: "https://edgemanager.lemans-sandbox.rockwellautomation.com/callback",
    scope: "openid profile email phone address my_rockwell sraapi",
    httpInterceptor: {
      allowedList: [
        {
          uri:  "https://edgemanager-api.lemans-sandbox.rockwellautomation.com/*",
        },
      ],
    },
  },
};

export const environment = {
  production: false,
  appConfiguration,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
