import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'


@Injectable({providedIn: 'root'})
export class SidenavService {

  public sideNavState$ = new BehaviorSubject(false);
  constructor() { }

}
