import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICancelTenantProvisioning, ICreateTenantProvisioning } from '../models/provisioning.model';
import { environment } from 'src/environments/environment';
import { cancelOrgApi, createOrgApi, defaultOrg , checkOrgState } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  createTenantProvisioning(payload: ICreateTenantProvisioning) {
    return this.http.post<ICreateTenantProvisioning>(environment.appConfiguration.apiBaseUrl + createOrgApi, payload);
  }

  cancelTenantProvisioning(payload: ICancelTenantProvisioning) {
    return this.http.post<null>(environment.appConfiguration.apiBaseUrl + cancelOrgApi +"/"+ payload.serviceId, payload);
  }

  defaultTenantName(){
    return this.http.get<string>(environment.appConfiguration.apiBaseUrl + defaultOrg)
  }

  checkTenantProvisioning(){
    return this.http.get<any>(environment.appConfiguration.apiBaseUrl + checkOrgState)
  }
}
