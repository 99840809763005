import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe } from '@angular/common';
import { AutoSignInComponent } from './auth/auto-sign-in/auto-sign-in.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ConfigService, CoreConfiguration, ServiceConfigProvider, ServiceModule } from '@rockwell-automation-inc/service';
import { AuthorizationService } from './shared/utils/services/authorization.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpMultiLoader } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { environment } from 'src/environments/environment';
import {
  LayoutModule,
} from '@rockwell-automation-inc/layout';
import { AppConfigService } from './shared/utils/services/app-config.service';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { RaUiImageModule } from '@ra-web-tech-ui-toolkit/common-utils/image';
import { RaUiFormModule } from '@ra-web-tech-ui-toolkit/form-system/form';
import { RaUiErrorMessageModule } from '@ra-web-tech-ui-toolkit/form-system/error-message';
import { RaUiLabelContainerModule } from '@ra-web-tech-ui-toolkit/common-utils/label-container';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/buttons/loading-spinner';
import {RaUiStatusIconModule} from '@ra-web-tech-ui-toolkit/common-utils/status-icon';
import { SubActiveGuard } from './shared/utils/guard/route.guard';
import { OrganizationService } from './create-organization/service/organization.service';
import { GlobalHttpInterceptorService } from './shared/utils/interceptors/interceptor.service';
// Client Side RowModel Module registered to use AGGrid
import {ModuleRegistry} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import { RaUiFormGridModule } from '@ra-web-tech-ui-toolkit/grid/form-grid';
ModuleRegistry.registerModules([ClientSideRowModelModule]);
async function initializeApp(
  appConfigService: AppConfigService
): Promise<any> {
  try {
    await appConfigService.init();
  } catch (err) {
    console.error('error initializing EaaS application', err);
  }
}

@Injectable()
export class ToolkitTranslateLoader extends TranslateHttpMultiLoader {
    constructor(http:HttpClient) {
        super(http, [{
            prefix: '@ra-web-tech-ui-toolkit/grid/assets/locals/',
            suffix: '.json'
        }, {
            prefix: '@ra-web-tech-ui-toolkit/common-utils/assets/locals/',
            suffix: '.json'
        }, {
            prefix: '@ra-web-tech-ui-toolkit/form-system/assets/locals/',
            suffix: '.json'
        }, {
            prefix: '@ra-web-tech-ui-toolkit/navigation/assets/locals/',
            suffix: '.json'
        }
      ]);
    }
}


@Injectable({ providedIn: 'root' })
export class ServiceConfigFromApp implements ServiceConfigProvider {
  constructor(private configService: ConfigService<CoreConfiguration>) {}
  get CoreConfiguration(): CoreConfiguration {
    return this.configService.config;
  }
}

// See documentation @ https://angular.io/api/core/APP_INITIALIZER
// WARNING: All errors need to be properly handled

@NgModule({
  declarations: [
    AppComponent,
    AutoSignInComponent,
    RedirectComponent,
    CreateOrganizationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RaUiInputModule,
    FormsModule,
    RaUiStaticTextModule,
    RaUiFormGridModule,
    RaUiButtonModule,
    RaUiImageModule,
    RaUiFormModule,
    ReactiveFormsModule,
    RaUiErrorMessageModule,
    BrowserAnimationsModule,
    RaUiLabelContainerModule,
    RaUiLoadingSpinnerModule,
    RaUiStatusIconModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: ToolkitTranslateLoader
      },
      isolate: true
  }),
    AppRoutingModule,
    SharedModule,
    LayoutModule,
    AuthModule.forRoot(),
    ServiceModule.forRoot(environment.appConfiguration)
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService<CoreConfiguration>) => () =>
        configService.setConfig(environment.appConfiguration),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          appConfigService: AppConfigService
        ) =>
          () =>
            initializeApp(
              appConfigService,
            ),
      deps: [
        AppConfigService
      ],
      multi: true,
    },
    DatePipe,
    AuthorizationService,
    AppConfigService,
    SubActiveGuard,
    OrganizationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


