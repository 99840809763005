<div
  class="sidenav_container icon_padding"
  [@onSideNavChange]="sideNavState ? 'open' : 'close'"
>
  <div fxLayout="column" fxLayoutGap="10px">
    <div class="user_menu">
      <mat-nav-list (click)="onSidenavToggle()">
        <a mat-list-item>
          <i
            [ngClass]="
              sideNavState === true
                ? 'ra-icon-ide-sm-caret-left nav-open'
                : 'ra-icon-ide-sm-caret-right'
            "
          ></i>
        </a>
      </mat-nav-list>
      <mat-divider></mat-divider>
    </div>

    <div class="menu-container">
      <ra-ui-tree
        (treeNodeClick)="onClick($event)"
        (treeInitialized)="onTreeInitialized($event)"
        [(tree)]="treeModel"
        [config]="config"
      >
      </ra-ui-tree>
    </div>
  </div>
</div>
