import { Injectable} from '@angular/core';
import { UserProfile } from '@rockwell-automation-inc/service';
import { AccountAttributes, IGainsightUserAttributes } from '../common-models/gainsight.model';
import { AuthorizationService } from './authorization.service';


// Replaced usage of private member with this declared function.
// Custom Events were not reaching gainsight when using other way, but this works.
export declare function aptrinsic(): any;
export interface GainsightAttributes {
  user: IGainsightUserAttributes;
  account: AccountAttributes;
}
@Injectable({
  providedIn: 'root'
})

export class GainsightService {
  constructor(private authorizationService:AuthorizationService ) { }
  async initGainsight(user: UserProfile): Promise<void> {
    const attrs = this.getGainsightAttributes(user) ?? {} as GainsightAttributes;
    this.identifyUser(attrs.user);
}
  identifyUser(audienceAttrs: IGainsightUserAttributes): void {
    try {
      // @ts-expect-error gainsight API
      aptrinsic('identify', audienceAttrs);
    } catch (error) {
      console.log(error);
    }
  }

  trackEvent(event: string, properties = {}): void {
    try {
      // @ts-expect-error gainsight API
      aptrinsic('track', event, properties);
    } catch (error) {
      console.log(error);
    }
  }

  getGainsightAttributes(user: UserProfile) {
    const audienceAttr: IGainsightUserAttributes = {
      company: user.company ?? "",
      city: user.location ?? "",
      email: user.email ?? "",
      firstName: user.name.split(" ")[0] ?? "",
      id: user.userId ?? "",
      lastName: user.name.split(" ")[1],
      tenantId: this.authorizationService.readTenantId() ?? "personal tenant",
      tenantName: "Eaas",
    };

    return {
      user: audienceAttr
    } as GainsightAttributes;
  }

}
