import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoSignInComponent } from './auth/auto-sign-in/auto-sign-in.component';
import { LayoutComponent } from './shared/ui-layout/layout/layout.component';
import {AuthGuardService} from '@rockwell-automation-inc/service';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { SubActiveGuard } from './shared/utils/guard/route.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo:'sign-in', //comment for running on node
    pathMatch:'full'
  },
  {
    path:'sign-in',
    component: AutoSignInComponent,
  },
  {
    path:'redirect',
    component: RedirectComponent,
  },
  {
    path:"page-state",
    loadChildren: () => import('./page-state/page-state.module')
    .then(m => m.PageStateModule)
  },
  {
    path: 'callback',
    redirectTo: 'dashboard',
  },
  {
    path: 'provision',
    canActivate:[SubActiveGuard,AuthGuardService],
    component: CreateOrganizationComponent
  },
  {
    path:"",
    component:LayoutComponent,
    canActivate:[AuthGuardService],
    children:[
      {
        path:"dashboard",
        loadChildren: () => import('./home/dashboard-feature-dashboard.module')
        .then(m => m.DashboardFeatureDashboardModule)
      },
      {
        path:"profiles",
        loadChildren: () => import('./edge-device-manager/edge-device-manager.module')
        .then(m => m.EdgeDeviceProfileModule)
      },
      {
        path:"marketplace",
        loadChildren: () => import('./edge-app-manager/edge-app-manager.module')
        .then(m => m.EdgeAppManagerModule)
      },
      {
        path:"devices",
        loadChildren: () => import('./edge-device-provisioning/edge-device-provisioning.module')
        .then(m => m.EdgedeviceprovisioningModule)
      },
      {
        path:"appinstances",
        loadChildren: () => import('./edge-app-instances/edge-app-instances.module')
        .then(m => m.EdgeAppInstancesModule)
      },
      {
        path:"page-state-internal",
        loadChildren: () => import('./page-state-internal/page-state-internal.module')
        .then(m => m.PageStateInternalModule)
      },
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
