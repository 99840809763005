import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private http: HttpClient) { }

  fetchServerVersion() {
    return this.http.get<any>(environment.appConfiguration.apiBaseUrl);
  }

  fetchUIVersion() {
    return this.http.get<string>('/version');
  }
}
