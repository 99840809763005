import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IIconButtonConfig } from '@ra-web-tech-ui-toolkit/buttons/button';
import { IconSize } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { IStaticTextConfig } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { IVersion } from '../models/version';
import { VersionService } from '../service/version.service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit{
  serverVersion: string = '';
  UiVersion: string = 'Unavailable';
  buttonConfig: IIconButtonConfig = {
    iconSize: IconSize.L,
    cssClass: 'red',
  };
  constructor(public dialogRef: MatDialogRef<AboutDialogComponent> , private service : VersionService) {}
  
  ngOnInit(): void {
    this.getServerVersion();
    this.getUiVersion();
  }

  public textConfig: IStaticTextConfig = {
    disableTruncate: true,
  };

  public closeButtonClicked() {
    this.dialogRef.close();
  }

  getUiVersion(): void {
    this.service.fetchUIVersion().subscribe({
      next: (version: string) => {
        this.UiVersion = ` ${version} (UI)`;
      },
    });
  }

  getServerVersion(): void {
    this.service.fetchServerVersion().subscribe({
      next: (response: IVersion) => {
        this.serverVersion = ` ${response.productVersion} (server)`;
      },
    });
  }
}
