import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { Subject } from 'rxjs';
import { ServerErrors } from 'src/app/constants';
import { IMessage } from '../common-models/message.model';

@Injectable({providedIn: 'root'})
export class SharedMessageService {

  $messageHandler = new Subject<IMessage>();

  getErrors(error:HttpErrorResponse){

    switch (error.status) {
      case 400:
        this.handleError(error)
      break;
      case 401:
        this.handleError(error)
        break;
      case 403:
        this.handleError(error)
        break;
      case 404:
        this.handleError(error,ServerErrors.notFountServerError.title)
        break;
      case 500:
        this.handleError(error,ServerErrors.internalServerError.title)
        break;
      case 0:
        this.handleError(error,error.statusText)
        break;
    }
  }

  handleError(error:HttpErrorResponse , internalError?:string){
    const message:IMessage = {
      message:internalError ? internalError : error.message,
      // title:internalError ? internalError : "Status: " + error.status.toString(),
      type:NotificationType.Error,
      showButtons:true
    }
  
    this.$messageHandler.next(message);  
  }
  showToastMessage(message:IMessage) {
    this.$messageHandler.next(message);   
  }

}
