import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedMatModulesModule } from './mat-modules/shared-mat-modules.module';
import { SharedUiLayoutModule } from './ui-layout/shared-ui-layout.module';
import { SharedUiRendererModule } from './ui-renderer/shared-ui-renderer.module';
import { SharedUiSectionHeaderModule } from './ui-section-header/shared-ui-section-header.module';
import { SharedGridComponent } from './shared-grid/shared-grid.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AppLogoPipe, AppTypeImagePipe, AppTypePipe } from './utils/pipes/app-type.pipe';
import { SharedPaginationComponent } from './shared-pagination/shared-pagination.component';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiFormModule } from '@ra-web-tech-ui-toolkit/form-system/form';
import { RaUiLabelContainerModule } from '@ra-web-tech-ui-toolkit/common-utils/label-container';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { RaUiSelectModule } from '@ra-web-tech-ui-toolkit/form-system/select';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { FormsModule } from '@angular/forms';
import { SharedActionBarComponent } from './shared-action-bar/shared-action-bar.component';
import { RaUiMenuModule } from '@ra-web-tech-ui-toolkit/popups/menu';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { NoDevicesAddedComponent } from './no-devices-added/no-devices-added.component';
import { RaUiImageModule } from '@ra-web-tech-ui-toolkit/common-utils/image';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SharedGridComponent,
    AppTypePipe,
    AppTypeImagePipe,
    AppLogoPipe,
    SharedPaginationComponent,
    SharedActionBarComponent,
    NoDevicesAddedComponent,
  ],
  imports: [
    CommonModule,
    SharedMatModulesModule,
    SharedUiLayoutModule,
    SharedUiRendererModule,
    SharedUiSectionHeaderModule,
    RaUiStaticTextModule,
    RaUiMenuModule,
    RaUiLabelContainerModule,
    RaUiSearchInputModule,
    RaUiButtonModule,
    AgGridModule,
    RaUiSelectModule,
    RaUiTooltipModule,
    RaUiFormModule,
    FormsModule,
    RaUiImageModule,
    RouterModule
  ],
  exports:[
    CommonModule,
    SharedMatModulesModule,
    SharedUiLayoutModule,
    SharedUiRendererModule,
    SharedUiSectionHeaderModule,
    SharedGridComponent,
    SharedPaginationComponent,
    SharedActionBarComponent,
    AppTypePipe,
    AppTypeImagePipe,
    AppLogoPipe,
    NoDevicesAddedComponent
  ]
})
export class SharedModule { }
