import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SharedMatModulesModule } from '../mat-modules/shared-mat-modules.module';
import { RaUiMainMenuBarModule } from '@ra-web-tech-ui-toolkit/navigation/main-menu-bar'
import { RouterModule } from '@angular/router';
import { RaUiBreadcrumbModule } from '@ra-web-tech-ui-toolkit/navigation/breadcrumb';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiLabelContainerModule } from '@ra-web-tech-ui-toolkit/common-utils/label-container';

@NgModule({
  imports: [CommonModule,
    RaUiBreadcrumbModule,
    SharedMatModulesModule,
    RouterModule,
    RaUiMainMenuBarModule,
    RaUiStaticTextModule,
    RaUiLabelContainerModule
  ],
  declarations: [
    SectionHeaderComponent
  ],
  exports:[SectionHeaderComponent]
})
export class SharedUiSectionHeaderModule {}
