import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IToastConfig,
  ToastConfig,
  ToastDirective,
} from '@ra-web-tech-ui-toolkit/popups/toast';
import { IMessage } from '../../utils/common-models/message.model';
import { SidenavService } from '../../utils/services/sidenav.service';
import { onMainContentChange } from '../animation/animation';
import { AuthorizationService } from '../../utils/services/authorization.service';
import { NavItem, NavItemActions, UserNotification } from '@rockwell-automation-inc/layout';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GainsightService } from '../../utils/services/gainsight.service';
import { AuthService, CommonService, HubConnectionProxy, SignalrService } from '@rockwell-automation-inc/service';
import { SharedMessageService } from '../../utils/services/shared-message.service';
import { Router } from '@angular/router';
import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


export enum HelpMenuItem {
  About = 'HelpAbout',
}
@Component({
  selector: 'eaas-hub-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [onMainContentChange],
})

export class LayoutComponent implements OnInit {
  public onSideNavChange: boolean = false;
  categories: NavItem[] = [];
  collapsedMenuCategories: string[] = [];
  tenantIdSubject$ = new BehaviorSubject<string>('');
  tenantId$ = this.tenantIdSubject$
    .asObservable()
    .pipe(filter((tenantId) => tenantId !== '' || tenantId !== null));
  getAccountMenuData$: any;
  // Notifications
  notificationAction$?: Observable<any>;
  orgs$: any;
  getCurrentTenantNameSubject$ = new BehaviorSubject<string>('abc');
  getCurrentTenantName$ = this.getCurrentTenantNameSubject$
    .asObservable()
    .pipe(filter((currentTenantName) => currentTenantName !== '' || currentTenantName !== null));
  @ViewChild(ToastDirective, { static: true }) toastRef!: ToastDirective;

  helpItems: NavItem[] = [
    {
      displayName: 'About',
      id: HelpMenuItem.About,
      action: NavItemActions.custom,
    },
  ];

  dialogConfig: MatDialogConfig = {
    maxWidth: 378,
  };

  constructor(
    private _sidenavService: SidenavService,
    private router: Router,
    public authService: AuthService,
    private sharedService: AuthorizationService,
    private gainsightService: GainsightService,
    private messageService: SharedMessageService,
    private commonService: CommonService,
    public signalRService: SignalrService,
    private dialog: MatDialog
  ) {
    const tenantId = this.sharedService.readTenantId();
    this.tenantIdSubject$.next(tenantId);
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.gainsightService.initGainsight(user);
        this.commonService.setUserId(user.userId);
        this.commonService.initializeNavbar$('').subscribe(() => {
        });
        this.commonService.getAccountMenuData$().subscribe(response => {
          this.getAccountMenuData$ = response;
        });
      }
    });


  }

  toastConfig: IToastConfig = new ToastConfig({
    useSingleLineMessage: false,
    disableStackMultiple: true,
    disableCloseButton: false,
    delay: 8000,
  });

  ngOnInit(): void {
    this.setSideNav();
    this.messageService.$messageHandler.subscribe((message: IMessage) => {
      this.displayToast(message);
    });
  }

  // Notifications
  getUnreadNotificationsCount$ =
    this.commonService.getUnreadNotificationsCount$();

  getUserNotifications$ = this.commonService
    .getUserNotifications$()
    .pipe(map((data) => data.records));

  getNotifications$ = this.commonService.getNotifications$();

  refreshNotificationsGrid$ = this.commonService
    .refreshNotificationsGrid$()
    .pipe(
      switchMap(() => this.commonService.getUserNotificationCounts$()),
      switchMap(() => this.commonService.getUserNotifications$())
    );

  getUserNotificationCounts$ = this.commonService.getUserNotificationCounts$();

  startSignalRConnection$ = this.authService.isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated === true),
    tap(() => {
      this.signalRService.startConnection();
    })
  );

  onHubConnected$ = this.signalRService.onHubConnected$.pipe(
    tap((hubProxy) => {
      this.onSignalRHubConnected(hubProxy);
    })
  );

  signalRUserNotifications$ = this.signalRService.userNotification$.pipe(
    withLatestFrom(
      this.commonService.getNotifications$(),
      this.commonService.getUnreadNotificationsCount$()
    ),
    tap(([notificationOnSignalR, notifications, unreadNotificationCount]) => {
      const existingNotification = notifications.find(
        (notification) => notification.id === notificationOnSignalR.id
      );

      if (!existingNotification && notificationOnSignalR.isRead === false) {
        const updatedNotifications = [
          notificationOnSignalR,
          ...notifications,
        ].slice(0, 10);
        this.commonService.setNotification(updatedNotifications);
        this.commonService.setUnreadNotificationsCount(
          (unreadNotificationCount += 1)
        );
      }

      if (
        existingNotification?.isRead === false &&
        notificationOnSignalR.isRead === true
      ) {
        const updatedNotifications = notifications.map((notification) => {
          if (notification.id === notificationOnSignalR.id) {
            notification.isRead = notificationOnSignalR.isRead;
            return notification;
          }
          return notification;
        });
        this.commonService.setNotification(updatedNotifications);
      }

      if (notificationOnSignalR.isRead === true) {
        this.commonService.setUnreadNotificationsCount(
          (unreadNotificationCount -= 1)
        );
      }
    })
  );

  notificationService$ = this.commonService.getUserNotifications$.bind(
    this.commonService
  );

  onSignalRHubConnected(hubProxy: HubConnectionProxy): void {
    hubProxy.on('Notify', (userNotification: UserNotification) =>
      console.log(userNotification)
    );
  }

  notificationAction(data: any): void {
    this.notificationAction$ =
      this.commonService.handleNotificationAction$(data);
  }

  logout() {
    this.authService.logout();
  }

  displayToast(toastMessage: IMessage) {
    if (toastMessage.showButtons) {
      this.toastRef.buttons = [{ label: 'Dismiss' }];
    } else {
      this.toastRef.buttons = [];
    }
    this.toastRef.addToast(
      toastMessage.message,
      toastMessage.type,
      toastMessage.title
    );
  }

  // set side navigation open state
  setSideNav() {
    this._sidenavService.sideNavState$.subscribe((res: boolean) => {
      this.onSideNavChange = res;
    });
  }

  // Setting on logo click
  homeClick() {
    this.router.navigate(['/devices/list']);
  }

  openMessageDialog() {
    this.dialog.open(AboutDialogComponent, this.dialogConfig);
  }

  helpItemClicked(event: NavItem) {
    switch (event.id) {
      case HelpMenuItem.About: {
        this.openMessageDialog();
        break;
      }
    }
  }
}
