import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor() { }

  setTenantId(token: string) {
    sessionStorage.setItem('tenantId',token)
  }

  setServiceId(Id: string) {
    sessionStorage.setItem('serviceId',Id)
  }

  removeSession() {
    sessionStorage.clear()
  }

  readTenantId(){
    return sessionStorage.getItem('tenantId') || ""
  }

  readServiceId(){
    return sessionStorage.getItem('serviceId') || ""
  }


}
