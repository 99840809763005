import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUserDetails } from '../model/userDetails.model';
import { RedirectService } from '../service/redirect.service';

@Component({
  template: '',
  providers: [RedirectService],
})
export class RedirectComponent implements OnInit {
  constructor(private apiService: RedirectService) {}

  ngOnInit(): void {
    this.getOrgAndEmail();
  }

  getOrgAndEmail() {
    this.apiService.getOrgNameEmail().subscribe({
      next: (result: IUserDetails) => {
        this.loginZededaRedirect(result);
      },
      error: () => {
        this.loginZededaRedirect();
      },
    });
  }

  loginZededaRedirect(UserDetails?: IUserDetails): void {
    if (UserDetails) {
      window.location.href =
        environment.appConfiguration.redirectUrl +
        `?user=${UserDetails.userEmail}&enterprise=${
          UserDetails.enterpriseName
        }`;
    } else {
      window.location.href = environment.appConfiguration.redirectUrl || '';
    }
  }
}
