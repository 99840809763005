import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListRendererComponent } from './list-renderer/list-renderer.component';
import { RaUiMenuModule } from '@ra-web-tech-ui-toolkit/popups/menu';
import { SharedMatModulesModule } from '../mat-modules/shared-mat-modules.module';
import { RaUiLinkMenuModule } from '@ra-web-tech-ui-toolkit/popups/link-menu';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { StatusComponent } from './status/status.component';
import { RaUiBadgeModule } from '@ra-web-tech-ui-toolkit/buttons/badge';
import { RaUiDialogModule } from '@ra-web-tech-ui-toolkit/popups/dialog';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/popups/toast';
import { LinkButtonRendererComponent } from './link-button-renderer/link-button-renderer.component';
import { RaUiStatusIconModule } from '@ra-web-tech-ui-toolkit/common-utils/status-icon';
import { RaUiActivityStatusModule } from '@ra-web-tech-ui-toolkit/form-system/activity-status';
import { CustomGridTooltip } from './custom-grid-tooltip/custom-grid-tooltip.component';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';

@NgModule({
  imports: [
    CommonModule,
    RaUiButtonModule,
    RaUiMenuModule,
    SharedMatModulesModule,
    RaUiLinkMenuModule,
    RaUiBadgeModule,
    RaUiDialogModule,
    RaUiToastModule,
    RaUiStatusIconModule,
    RaUiActivityStatusModule,
    RaUiTooltipModule
  ],
  declarations: [
    ListRendererComponent,
    ActionMenuComponent,
    StatusComponent,
    LinkButtonRendererComponent,
    CustomGridTooltip
  ],
  exports:[],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedUiRendererModule { }
