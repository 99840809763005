import { InjectionToken } from "@angular/core";
import { Configuration } from "./models/config.model";

export const APP_CONFIG: InjectionToken<Configuration>
  = new InjectionToken<Configuration>('Application Configuration');
export const PAGE_SIZE = 25;
export const GRID_HEADER_HEIGHT=48;
export const GRID_ROW_HEIGHT=48;
const apiVersion = '/api/v1'
export const edgeDeviceListApi =  `${apiVersion}/EdgeDevices`;
export const edgeAppDefinitionListApi =  `${apiVersion}/EdgeAppDefinition`;
export const edgeAppDefinitionDetailsApi =  `${apiVersion}/EdgeAppDefinition/`;
export const appInstanceListApi =  `${apiVersion}/EdgeApp`;
export const appBrandsApi =  `${apiVersion}/EdgeDeviceProfiles/brands`;
export const addEdgeDeviceApi =  `${apiVersion}/EdgeDevices`;
export const createOrgApi =  `${apiVersion}/Tenant`;
export const cancelOrgApi =  `${apiVersion}/Tenant/cancel`;
export const defaultOrg = `${apiVersion}/Tenant/TenantName`;
export const checkOrgState = `${apiVersion}/Tenant/ServiceProvisioningStatus`;

export const defaultPage = "/provision";
export const apiPoolingInterval = 5000



export const deviceStates=[
  {
    value: 'Provisioned',
    color: '#7E5986',
    label: 'Provisioned',
    icon: 'ra-icon-ide-md-device-list',
  },
  {
    value: 'Unprovisioned',
    color: '#8F2ADB',
    label: 'Unprovisioned',
    icon: 'ra-icon-ide-md-create-device',
  },
  {
    value: 'Other',
    color: '#5D6063',
    label: 'Other',
    icon: 'ra-icon-ide-md-device-unknown',
  },
  {
    value: 'Online',
    color: '#12850A',
    label: 'Online',
    icon: 'ra-icon-ide-sm-color-status-indicator-circle-check',
  },
  {
    value: 'Initializing',
    color: '#235D9F',
    label: 'Initializing',
    icon: 'ra-icon-ide-md-mode-on',
  },
  {
    value: 'Initialized',
    color: '#235D9F',
    label: 'Initialized',
    icon: 'ra-icon-ide-md-mode-on',
  },
  {
    value: 'Unknown',
    color: '#8F2AD2',
    label: 'Unknown',
    icon: 'ra-icon-ide-md-page-view',
  },
  {
    value: 'Halted',
    color: '#00000',
    label: 'Halted',
    icon: 'ra-icon-ide-sm-ao',
  },
  {
    value: 'Rebooting',
    color: '#00000',
    label: 'Rebooting',
    icon: 'ra-icon-ide-sm-history',
  },
  {
    value: 'Downloading',
    color: '#00000',
    label: 'Downloading',
    icon: 'ra-icon-ide-md-pull',
  },
  {
    value: 'Restarting',
    color: '#00000',
    label: 'Restarting',
    icon: 'ra-icon-ide-sm-running',
  },
  {
    value: 'Purging',
    color: '#00000',
    label: 'Purging',
    icon: 'ra-icon-ide-md-hide-guides'
  },
  {
    value: 'Verifying',
    color: '#00000',
    label: 'Verifying',
    icon: 'ra-icon-ide-sm-project-validation'
  },
  {
    value: 'Loading',
    color: '#00000',
    label: 'Loading',
    icon: 'ra-icon-ide-md-add-history'
  },
  {
    value: 'Creating Volume',
    color: '#00000',
    label: 'Creating Volume',
    icon: 'ra-icon-ide-md-layers'
  },
  {
    value: 'Booting',
    color: '#00000',
    label: 'Booting',
    icon: 'ra-icon-ide-md-system'
  },
  {
    value: 'Maintenance',
    color: '#00000',
    label: 'Maintenance',
    icon: 'ra-icon-ide-md-pulse'
  },
  {
    value: 'Halting',
    color: '#00000',
    label: 'Halting',
    icon: 'ra-icon-ide-sm-ao-override',
  },
  {
    value: 'Offline',
    color: '#5D6063',
    label: 'Offline',
    icon: 'ra-icon-ide-sm-color-status-indicator-circle-offline',
  }
]
export const applicationStates = [{
  value: 'Other',
  color: '#5D6063',
  label: 'Other',
  icon: 'ra-icon-ide-md-one-view',
},...deviceStates]


export const ServerErrors = {
  internalServerError: { title: 'Internal Server Error!', message: 'Internal Server Error!' },
  notFountServerError: {
    title: '404 not found!!',
    message: '404 not found!!',
  },
};


