import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  NotificationType,
  TextAlign,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import {
  IStaticTextConfig,
  StaticTextConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import {
  RaUiFormControl,
  RaUiFormGroup,
} from '@ra-web-tech-ui-toolkit/form-system/form';
import {
  IInputConfig,
  InputConfig,
} from '@ra-web-tech-ui-toolkit/form-system/input';
import {
  LabelContainerConfig,
  LabelContainerLayout,
} from '@ra-web-tech-ui-toolkit/common-utils/label-container';
import { Subject, Subscription } from 'rxjs';
import {
  IErrorMessageConfig,
  IErrorState,
} from '@ra-web-tech-ui-toolkit/form-system/error-message';
import { ErrorMessageConfig } from '@ra-web-tech-ui-toolkit/form-system/error-message';
import {
  LoadingSpinnerSize,
  LoadingSpinnerDefinedSize,
  AnimationType,
} from '@ra-web-tech-ui-toolkit/buttons/loading-spinner';
import { OrganizationService } from './service/organization.service';
import {
  ICancelTenantProvisioning,
  ICreateTenantProvisioning,
} from './models/provisioning.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthorizationService } from '../shared/utils/services/authorization.service';
import words from './validators/bad-words.json';
import { interval, retry, startWith, switchMap, takeUntil } from 'rxjs';
import { apiPoolingInterval } from '../constants';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss'],
  providers: [OrganizationService],
})
export class CreateOrganizationComponent
  implements AfterContentInit, OnDestroy
{
  size: LoadingSpinnerSize = LoadingSpinnerDefinedSize.Large;
  animationType: AnimationType = AnimationType.Spinner;
  isLoading = false;
  logo = 'FTED';
  themeColor = ThemeColor;
  disablePadding = true;
  disabled: boolean = true;
  isMultiLine: boolean = true;
  confirmState: boolean = false;
  isError = false;
  isDirty = false;
  tenantId: string = '';
  serviceId: string = '';
  badWordsList: Array<string> = words;
  isDirtyFooSubscription!: Subscription;
  isBadWord: boolean = false;
  activeStatusIcon = NotificationType.Error;
  errorType = '';
  isInternalError: boolean = false;
  servicePolling! : Subscription;
  private stopPolling = new Subject();
  labelConfig = new LabelContainerConfig({
    labelContainerLayout: LabelContainerLayout.Vertical,
    fillParent: true,
    addColon: false,
  });
  TextConfigTitle: IStaticTextConfig = new StaticTextConfig({
    disableTruncate: true,
    allowMultiline: false,
    textAlign: TextAlign.Center,
  });

  TextConfigSubtitle: IStaticTextConfig = new StaticTextConfig({
    disableTruncate: true,
    allowMultiline: true,
    textAlign: TextAlign.Left,
  });

  TextConfigSubtitleSuccess: IStaticTextConfig = new StaticTextConfig({
    disableTruncate: true,
    allowMultiline: true,
    textAlign: TextAlign.Center,
  });

  errorConfig: IErrorMessageConfig = new ErrorMessageConfig({
    disableTooltip: true,
  });

  formGroup: RaUiFormGroup = new RaUiFormGroup({
    name: new RaUiFormControl('', [
      Validators.minLength(3),
      Validators.maxLength(256),
      Validators.pattern('[a-zA-Z0-9 _.-]+'),
    ]),
  });

  nameControl = this.formGroup.controls.name;

  inputConfig: IInputConfig = new InputConfig({
    fillParent: true,
    enableFlatMode: true,
    disableSidePaddings: true,
  });

  constructor(
    private apiService: OrganizationService,
    private sharedService: AuthorizationService
  ) {}

  ngAfterContentInit() {
    // this.setDefaultName()
    this.isDirtyFooSubscription = (<RaUiFormControl>(
      this.formGroup.controls.name
    )).dirtyChanges.subscribe((isDirty: boolean) => {
      this.isDirty = isDirty;
    });
    this.paramsCheck();
    this.formGroup.markAllAsTouched();
    this.setDefaultName();
  }

  // Setting default name
  setDefaultName() {
    this.isLoading = true;
    this.apiService.defaultTenantName().subscribe({
      next: (data: string) => {
        this.isLoading = false;
        this.formGroup.patchValue({ name: data });
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  // Fix default incoming name format
  SpaceCheck(value: string): string {
    while (value.length < 3) {
      value = value + '_';
    }
    return value.replaceAll(' ', '_');
  }

  ngOnDestroy() {
    this.isDirtyFooSubscription.unsubscribe();
    this.stopPolling.next('');
  }

  badWordCheck(word: string) {
    const isBad = this.badWordsList.find((element) => element == word);
    if (isBad) {
      this.isBadWord = true;
      this.formSetBadWordError();
    } else if (this.isBadWord) {
      this.formRemoveBadWordError();
      this.isBadWord = false;
    }
  }

  setError(state: IErrorState) {
    this.isInternalError = false;
    this.isError = state.isError;
  }

  onReturnClick() {
    this.confirmState = false;
  }

  paramsCheck() {
    this.tenantId = this.sharedService.readTenantId();
    this.serviceId = this.sharedService.readServiceId();
    if (this.tenantId == '' || this.serviceId == '') {
      this.isInternalError = true;
    } else {
      this.isInternalError = false;
    }
  }

  errorMessages = {
    pattern:
      'Please use only Alphanumeric and _.- characters. Minimum 3, Max 256',
    minlength: 'Minimum 3 characters are required',
    maxlength: 'Maximum 256 characters are allowed',
    internalError: '',
    ExistingNameError: 'That name is already used elsewhere.',
    badWord: 'That appears to use swear words. Please choose something else.',
  };

  formSetBadWordError() {
    let control = this.formGroup.controls['name'];
    this.formGroup.markAllAsTouched();
    if (control) {
      control.setErrors({ badWord: true });
    }
  }

  formRemoveBadWordError() {
    let control = this.formGroup.controls['name'];
    this.formGroup.markAllAsTouched();
    if (control) {
      control.setErrors({ badWord: null });
      control.updateValueAndValidity();
    }
  }

  formSetDuplicateError() {
    let control = this.formGroup.controls['name'];
    this.formGroup.markAllAsTouched();
    if (control) {
      control.setErrors({ ExistingNameError: true });
    }
  }

  formSetInternalError() {
    let control = this.formGroup.controls['name'];
    this.formGroup.markAllAsTouched();
    if (control) {
      control.setErrors({ internalError: true });
    }
  }

  formRemoveInternalError() {
    let control = this.formGroup.controls['name'];
    this.formGroup.markAllAsTouched();
    if (control) {
      control.setErrors({ internalError: null });
      control.updateValueAndValidity();
    }
  }

  onSubmit() {
    this.errorType = '';
    this.isLoading = true;
    this.isInternalError = false;

    if (this.formGroup.invalid || this.formGroup.controls['name'].value == '') {
      this.isLoading = false;
      return;
    }

    let tempData: ICreateTenantProvisioning = {
      name: this.formGroup.controls['name'].value,
      serviceId: this.serviceId,
    };

    this.apiService.createTenantProvisioning(tempData).subscribe({
      next: () => {
        this.isLoading = false;
        this.confirmState = true;
        this.stopPolling.next('');
        this.checkForError()
      },
      error: (error: HttpErrorResponse) => {
        this.errorType = 'Saving';
        this.isLoading = false;
        this.confirmState = false;
        switch (error.status) {
          case 400:
            this.isInternalError = true;
            break;
          case 409:
            this.formSetDuplicateError();
            break;
          default:
            this.isInternalError = true;
            break;
        }
      },
    });
  }

  cancel(): void {
    this.errorType = '';
    this.isLoading = true;
    this.isInternalError = false;
    let tempData: ICancelTenantProvisioning = {
      serviceId: this.serviceId,
    };

    this.apiService.cancelTenantProvisioning(tempData).subscribe({
      next: () => {},
      error: () => {
        this.errorType = 'Cancelling';
        this.isLoading = false;
        this.confirmState = false;
        this.isInternalError = true;
      },
    });
  }

  checkForError(){
    this.servicePolling = interval(apiPoolingInterval).pipe(
      startWith(5000),
      switchMap(() => this.apiService.checkTenantProvisioning()),
      takeUntil(this.stopPolling),
      retry({count: 2, delay: 5000})
    ).subscribe({
      error: () => {
        this.errorType = 'Saving';
        this.isLoading = false;
        this.confirmState = false;
        this.isInternalError = true;
      }
    })
  }

}
