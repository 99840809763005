<div class="nav_height">
  <ng-container *ngIf="authService.isAuthenticated$ | async">
    <ng-container
      *ngIf="(startSignalRConnection$ | async) && (onHubConnected$ | async)"
    >
    </ng-container>
    <ng-container *ngIf="getUserNotificationCounts$ | async"></ng-container>
    <ng-container *ngIf="signalRUserNotifications$ | async"></ng-container>
    <ng-container *ngIf="notificationAction$ | async"></ng-container>
    <ng-container *ngIf="refreshNotificationsGrid$ | async"></ng-container>
    <ng-container *ngIf="getUserNotifications$ | async"></ng-container>
  </ng-container>
  <lib-common-mat-navbar
    [enableUserActionsSideNav]="false"
    [enableNotificationsSideNav]="true"
    [enableHelpSideNav]="true"
    [enableOrganizationSwitcher]="false"
    [enableUserProfile]="true"
    [isAuthenticated]="true"
    [accountMenu]="getAccountMenuData$"
    (notificationAction)="notificationAction($event)"
    [unreadNotificationsCount]="(getUnreadNotificationsCount$ | async)!"
    [notifications]="(getNotifications$ | async)!"
    [notificationService$]="notificationService$"
    [logotypeClass]="'ft-hub'"
    (raLogoClick)="homeClick()"
    [currentTenantId]="(tenantId$ | async)!"
    (logoutClick)="logout()"
    [helpItems]="helpItems"
    (helpItemClick)="helpItemClicked($event)"
    (goToHomeClick)="homeClick()"
    (raLogoClick)="homeClick()"
    [mfaReset]="true" 
  >
  <div class="main-container">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #leftSidenav mode="side" opened>
        <!-- <eaas-hub-sidebar></eaas-hub-sidebar> -->
        <eaas-hub-sidebar-new></eaas-hub-sidebar-new>
      </mat-sidenav>
      <mat-sidenav-content
        [@onMainContentChange]="onSideNavChange ? 'open' : 'close'"
      >
        <div class="main_content">
          <div class="main_body">
            <router-outlet></router-outlet>

          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  </lib-common-mat-navbar>
</div>

<div
  raUiToast
  class="custom-toast"
  fxLayout="column"
  fxLayoutAlign="center center"
  [toastConfig]="toastConfig"
></div>
