import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreConfiguration } from '@rockwell-automation-inc/service';

import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/constants';
import { environment } from './environments/environment';
//import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

//ModuleRegistry.registerModules([ServerSideRowModelModule]);

fetch('./assets/config/config.json')
  .then(response => response.json())
  .then((config: CoreConfiguration) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG , useValue: config },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
