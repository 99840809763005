import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { SidebarOldComponent } from './sidebar-old/sidebar-old.component';
import { SharedMatModulesModule } from '../mat-modules/shared-mat-modules.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AuthorizationService } from '../utils/services/authorization.service';
import { LayoutComponent } from './layout/layout.component';
import {RaUiTreeModule} from '@ra-web-tech-ui-toolkit/lists/tree';
import {SkyhookDndModule} from '@ra-web-tech-ui-toolkit/skyhook-core';
import {createDefaultMultiBackend, SkyhookMultiBackendModule, HTML5ToTouch} from '@ra-web-tech-ui-toolkit/skyhook-multi-backend';
import { AuthService } from '@auth0/auth0-angular';
import {RaUiToastModule} from '@ra-web-tech-ui-toolkit/popups/toast';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { VersionService } from './service/version.service';
import { SidenavNewComponent } from './sidenav-new/sidenav-new.component';


@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    SharedMatModulesModule,
    RouterModule,
    HttpClientModule,
    RaUiTreeModule,
    RaUiToastModule,
    FlexLayoutModule,
    RaUiStaticTextModule,
    RaUiButtonModule,
    // SharedUtilsModule,
    SkyhookMultiBackendModule,
    SkyhookDndModule.forRoot({backend: createDefaultMultiBackend(), options: HTML5ToTouch})
  ],
  declarations: [
    SidebarOldComponent,
    LayoutComponent,
    AboutDialogComponent,
    SidenavNewComponent
  ],
  exports:[
    LayoutComponent
  ],
  providers: [
    AuthorizationService,
    AuthService,
    VersionService
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedUiLayoutModule {}
